import { Box, Button, FormControl, Input, ListItemText, MenuItem, Modal, Select } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {IoCloseCircleOutline} from 'react-icons/io5'
import '../../../Assets/style/style.scss'
import { useEffect, useState } from "react";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Astrisks from "../../../Components/Common/astrisk";
import { useTranslation } from "react-i18next";
import { addCoupons, getCoupons } from "../../../Components/Redux/Slice/Coupons/coupons";

const AddCoupon = ({open,close,type,data}) => {
    const {t, i18n} = useTranslation()
    const form = useForm()
    const {register,control,handleSubmit,formState,setValue,getValues,watch,reset} = form
    const {errors, isDirty} = formState
    const dispatch = useDispatch()
    const [textareaCounter, settextareaCounter] = useState(0);
    const [textareaArCounter, settextareaArCounter] = useState(0);
    const [date, setdate] = useState(null)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 850,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 3,
        p: 2,
        borderRadius : 5,
    };

    const {coupons} = useSelector(state=>state)

    const [isFile, setisFile] = useState(null)

    const {file} = useSelector(state=>state)

    let page = 1,
        size = 10;

    const submitHandler = async (data)=>{

        try {

                await dispatch(addCoupons(data))
                await dispatch(getCoupons({ page, size })).then( ()=> reset() )
                setdate(null)
                setdiscountType(null)
                close();
        } catch (error) {
            // console.log(error);
        }
        
    }
    
    useEffect(() => {
        // console.log(file?.data?.url);
    }, [file,isFile,setValue])
    
    // handle status controller
    const [discountType, setdiscountType] = useState([]);

    // const {discountTypes} = useSelector(state=>state?.offers)

    useEffect(() => {
        // dispatch(getDiscountTypes())

        // console.log(getValues());
    }, [dispatch,getValues])

    // console.log(discountTypes); 
    

    const handleDiscountTypeSelectChange = (event) => {
        const { target: { value },} = event;
        setdiscountType(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
        setValue('discountType', value,{shouldValidate:true})
    }; 


    useEffect(() => {
        let newDate = date ? date.toISOString() : null;
        setValue('expiryDate', newDate);
    }, [date]);
    
    useEffect(() => {
        // console.log(textareaCounter);
    }, [textareaCounter,textareaArCounter])

    // console.log(offers);

    return ( 
        <>
            <Modal
                open={open}
                onClose={()=>{
                    close()
                    reset()
                    setdate(null)
                }}
                aria-labelledby="add-offer-modal-title"
                aria-describedby="add-offer-modal-description"
            >
                <Box 
                    sx={style} 
                    className='w-[95%] sm:w-[80%] md:w-[70%] lg:w-[60%] max-w-[990px]'
                    dir={localStorage?.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                >

                    <div className="flex justify-between items-center">
                        <h2 className="text-[#333333] text-lg font-bold mb-3 capitalize">{t('coupons.add_btn')}</h2>

                        <div>
                            <Button color="error" className="text-2xl -mt-2" onClick={()=>{
                                close()
                                reset()
                                setdate(null)
                            }}><IoCloseCircleOutline /></Button>
                        </div>
                    </div>
                    
                    <form onSubmit={handleSubmit(submitHandler)} noValidate className="menu-modal-form-wrapper mt-5 ">
                        <div className="flex flex-wrap lg:flex-nowrap gap-3 lg:gap-x-10 ">
                            <div className="input-wrapper max-h-[600px] overflow-y-auto basis-full flex flex-wrap justify-between items-start [&>div]:mb-2 [&>div>label]:capitalize [&>div>label]:text-[#333333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-2 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">

                                {/* <div className="basis-full lg:basis-[48%]">
                                    <label>{t('coupons.form.name_en')} <Astrisks /></label>
                                    <input type="text" id="name"
                                        {...register('name',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            minLength : {
                                                value : 3,
                                                message : t('common.min_length',{length : '3'})
                                            },
                                            maxLength : {
                                                value : 50,
                                                message : t('common.max_length',{length : '50'})
                                            }
                                        })} 

                                        minLength={3}
                                        maxLength={50}
                                    />

                                    {errors?.name?.message && <p className="capitalize text-sm text-danger">{errors?.name?.message}</p>}
                                </div>

                                <div className="basis-full lg:basis-[48%]">
                                    <label>{t('coupons.form.name_ar')} <Astrisks /></label>
                                    <input type="text" id="nameAr"
                                        {...register('nameAr',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            pattern: {
                                                value: /^[\u0621-\u064A\s&]+$/,
                                                message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية والمسافات وعلامه & والارقام فقط' : 'Only Arabic letters, spaces, & sign and numbers are allowed'
                                            },
                                            minLength : {
                                                value : 3,
                                                message : t('common.min_length',{length : '3'})
                                            },
                                            maxLength : {
                                                value : 50,
                                                message : t('common.max_length',{length : '50'})
                                            }
                                        })} 

                                        minLength={3}
                                        maxLength={50}
                                    />

                                    {errors?.nameAr?.message && <p className="capitalize text-sm text-danger">{errors?.nameAr?.message}</p>}
                                </div> */}
                                
                                <div className="basis-full lg:basis-[48%] [&>div]:bg-[#f3f6f9]">
                                    <label>{t('coupons.form.discount_type')} <Astrisks /></label>
                                    <FormControl sx={{width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name=""
                                            value={discountType}
                                            rules={{required:t('common.required')}}
                                            {...register('discountType',{
                                                required:{
                                                    value : true,
                                                    message: t('common.required')
                                                }
                                            })}
                                            render={({field,fieldState: { error }})=>(
                                                <>
                                                    <Select {...field}
                                                        displayEmpty
                                                        inputRef={{...register('discountType')}}
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="discountType"
                                                        value={discountType}
                                                        onChange={handleDiscountTypeSelectChange}
                                                        // renderValue={(selected) => selected.join(', ')}
                                                        sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'11px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}, '& .css-tzsjye' : {backgroundColor : '#f3f6f9'}}}
                                                    >

                                                        <MenuItem value={'AMOUNT'}>
                                                            <ListItemText primary={'AMOUNT'} />
                                                        </MenuItem>

                                                        <MenuItem value={'PERCENTAGE'}>
                                                            <ListItemText primary={'PERCENTAGE'} />
                                                        </MenuItem>
                                                    </Select>                                                    
                                                </>
                                            )}
                                            
                                        />
                                        
                                    </FormControl>

                                    {errors?.discountType?.message && <p className="capitalize text-sm text-danger mt-1">{errors?.discountType?.message}</p>}
                                </div>

                                <div className="basis-full lg:basis-[48%]">
                                    <label>{t('coupons.form.discount_value')} <Astrisks /></label>

                                    <FormControl variant="standard" className="w-full" sx={{'& .css-1mock2e-MuiInputBase-root-MuiInput-root:before': {border : 'none'}, '.css-1mock2e-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before':{border : 'none'}}}>
                                        <Input
                                            fullWidth
                                            sx={{p:'8px',border:'none'}}
                                            type="number"
                                            id="discountValue"
                                            className="text-mainColor bg-[#f3f6f9] w-full border-none rounded-md"
                                            {...register('discountValue',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                valueAsNumber: true,
                                                validate: value => Number.isInteger(value) || t('common.integerOnly')
                                            })}
                                            inputProps={{
                                                min:0
                                            }}
                                            // endAdornment={<InputAdornment position="start">%</InputAdornment>}
                                        />
                                    </FormControl>

                                    {errors?.discountValue?.message && <p className="capitalize text-sm text-danger">{errors?.discountValue?.message}</p>}
                                </div>

                                <div className="basis-full lg:basis-[48%]">
                                    <label>{t('coupons.form.coupon_code')} <Astrisks /></label>
                                    <FormControl variant="standard" className="w-full" sx={{'& .css-1mock2e-MuiInputBase-root-MuiInput-root:before': {border : 'none'}, '.css-1mock2e-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before':{border : 'none'}}}>
                                        <Input
                                            fullWidth
                                            sx={{p:'8px',border:'none'}}
                                            id="couponCode"
                                            className="text-mainColor bg-[#f3f6f9] w-full border-none rounded-md"
                                            {...register('couponCode',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                minLength : {
                                                    value : 3,
                                                    message : t('common.min_length',{length : '3'})
                                                },
                                                maxLength : {
                                                    value : 50,
                                                    message : t('common.max_length',{length : '50'})
                                                }
                                            })}
                                            // endAdornment={<InputAdornment position="start">%</InputAdornment>}
                                            minLength = {3}
                                            maxLength = {50}
                                        />
                                    </FormControl>

                                    {errors?.couponCode?.message && <p className="capitalize text-sm text-danger">{errors?.couponCode?.message}</p> }
                                </div>

                                <div className="basis-full lg:basis-[48%] [&>div]:bg-[#f3f6f9] [&>div]:rounded-md [&>div>div>input]:py-[12px]">
                                    <label>{t('coupons.form.expire_date')} <Astrisks /></label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            {...register('expiryDate',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                            })} 
                                            className="w-full p-0"
                                            sx={{'& fieldset': {border:0,outline:0}}}
                                            id='expiryDate'
                                            value={date}
                                            onChange={(e) => {
                                                // setdate(e?.toDate())
                                                setdate(e);
                                            }}
                                            // defaultValue={dayjs(data?.expirationDate)}
                                            // closeOnSelect
                                            // name={name}
                                            // onBlur={onBlur}
                                            
                                        />
                                    </LocalizationProvider>

                                    {errors?.expiryDate?.message && <p className="capitalize text-sm text-danger">{errors?.expiryDate?.message}</p>}

                                </div>
                                {discountType && discountType[0] === 'PERCENTAGE' ? 
                                
                                    <div className="basis-full lg:basis-[48%]">
                                        <label>{t('coupons.form.maxAmount')} <Astrisks /></label>
                                        <input type="text" id="maxAmount"
                                            {...register('maxAmount',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                pattern : {
                                                    value: /^[0-9]*$/,
                                                    message: t('common.onlyNumbers')
                                                }
                                            })} 

                                        />

                                        {errors?.maxAmount?.message && <p className="capitalize text-sm text-danger">{errors?.maxAmount?.message}</p>}
                                    </div>

                                    : 
                                    null
                                }

                                <div className={` ${discountType && discountType[0] === 'PERCENTAGE' ? 'basis-full lg:basis-[48%]' : 'basis-full lg:basis-[48%]' }`}>
                                    <label>{t('coupons.form.maxRedemptionsPerUser')} <Astrisks /></label>
                                    <input type="text" id="maxRedemptionsPerUser"
                                        {...register('maxRedemptionsPerUser',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            pattern : {
                                                value: /^[0-9]*$/,
                                                message: t('common.onlyNumbers')
                                            }
                                            
                                        })} 
                                    />

                                    {errors?.maxRedemptionsPerUser?.message && <p className="capitalize text-sm text-danger">{errors?.maxRedemptionsPerUser?.message}</p> }
                                </div>

                                <div className={` ${discountType && discountType[0] === 'PERCENTAGE' ? 'basis-full' : 'basis-full lg:basis-[48%]' }`}>
                                    <label>{t('coupons.form.maxTotalRedemptions')} <Astrisks /></label>
                                    <input type="text" id="maxTotalRedemptions"
                                        {...register('maxTotalRedemptions',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            pattern : {
                                                value: /^[0-9]*$/,
                                                message: t('common.onlyNumbers')
                                            }
                                            
                                        })} 
                                    />

                                    {errors?.maxTotalRedemptions?.message && <p className="capitalize text-sm text-danger">{errors?.maxTotalRedemptions?.message}</p>}
                                </div>

                                {/* <div className="basis-full">
                                    <label>{t('coupons.form.desc_en')} <Astrisks /></label>
                                    <div className="relative">
                                        <textarea 
                                            onKeyUp={(e)=>settextareaCounter(e.target.value.length)}
                                            className="resize-none w-full h-[70px] bg-[#f3f6f9] p-3 focus:outline-slate-400 rounded-md text-mainColor"
                                            id="description"
                                            {...register('description',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                minLength : {
                                                    value : 10,
                                                    message : t('common.min_length',{length : '10'})
                                                },
                                                maxLength : {
                                                    value : 250,
                                                    message : t('common.max_length',{length : '250'})
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
                                                    message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وجميع الرموز الخاصة' : 'Only English letters, numbers, and all special characters are allowed'
                                                },
                                            })}

                                            minLength={10}
                                            maxLength={250}
                                        />
                                        <p className="absolute bottom-2 right-2 text-[12px] text-[#0000005a]">{250 - textareaCounter}</p>
                                    </div>

                                    {errors?.description?.message && <p className="capitalize text-sm text-danger">{errors?.description?.message}</p> }

                                </div>

                                <div className="basis-full">
                                    <label>{t('coupons.form.desc_ar')} <Astrisks /></label>
                                    <div className="relative">
                                        <textarea 
                                            onKeyUp={(e)=>settextareaArCounter(e.target.value.length)}
                                            className="resize-none w-full h-[70px] bg-[#f3f6f9] p-3 focus:outline-slate-400 rounded-md text-mainColor"
                                            id="descriptionAr"
                                            {...register('descriptionAr',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                minLength : {
                                                    value : 10,
                                                    message : t('common.min_length',{length : '10'})
                                                },
                                                maxLength : {
                                                    value : 250,
                                                    message : t('common.max_length',{length : '250'})
                                                },
                                                pattern: {
                                                    value: /^[\u0621-\u064A0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
                                                    message: localStorage.getItem('lang') === 'ar' ? 'مسموح بالاحرف العربية والمسافات والارقام والرموز الخاصة' : 'Arabic letters, spaces, numbers and special characters are allowed'
                                                },
                                            })}

                                            minLength={10}
                                            maxLength={250}
                                        />
                                        <p className="absolute bottom-2 right-2 text-[12px] text-[#0000005a]">{250 - textareaArCounter}</p>
                                    </div>

                                    {errors?.descriptionAr?.message && <p className="capitalize text-sm text-danger">{errors?.descriptionAr?.message}</p>}

                                </div> */}


                            </div>

                            {/* <div className="basis-full lg:basis-[35%]">
                                <div className="">
                                    <div className="preview-wrapper shadow-md w-full h-[200px] relative p-2 bg-[#f7f5fd] rounded-md">
                                        <div className="absolute top-0 right-0 bottom-0 left-0 cursor-pointer w-full">
                                            <input type="file" id="file" accept="image/*" onChange={handleImageChange} className="absolute right-0 cursor-pointer opacity-1 w-full h-full z-10 opacity-0" />
                                        </div>
                                        {preview && <img src={preview} alt="Preview" className="w-full h-full rounded-md" />}
                                        { !imgerror && !preview ? <p className="capitalize text-sm text-black italic absolute top-1/2 left-1/2 -translate-x-1/2 z-10">please select image</p> : <p className="capitalize text-sm text-red-500 italic absolute top-1/2 left-1/2 -translate-x-1/2">{imgerror}</p> }
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div className="add-new-category-btn mt-0 text-right">
                            <Button type="submit" disabled={file?.loading || coupons?.loading } className="disabled:opacity-50 disabled:cursor-not-allowed bg-mainColor rounded-md capitalize text-white text-[12px] px-10 py-2">{t('coupons.form.add_btn')}</Button>
                        </div>
                    </form>
                </Box>
            </Modal>

            {/* {file?.loading || offers?.loading ? <Overlay /> : null} */}
        </>
    );
}
export default AddCoupon;