
import { Button, FormControl, IconButton, InputAdornment, ListItemButton, Menu, MenuItem, OutlinedInput } from '@mui/material';
import {FaEdit} from 'react-icons/fa'
import { useCallback, useEffect, useRef, useState } from 'react';
import {CiSearch} from 'react-icons/ci'
import DataTable from 'react-data-table-component';
import {BiDotsHorizontalRounded} from 'react-icons/bi'
import {IoMdAddCircle, IoMdEye} from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux';
import { openHandler } from '../../Components/Redux/Slice/common/overlaySlice';
import discount from '../../Assets/imgs/discount.svg'
import {MdNotInterested, MdOutlineDeleteSweep} from 'react-icons/md'
import {LuCheckCircle, LuDelete} from 'react-icons/lu'
import { AiOutlineCheckCircle } from "react-icons/ai"; 
import Overlay from '../../Components/Common/overlay';
import ColumnSelection from '../../Components/Common/columnSelection';
import { useRemoteSort } from '../../Components/Common/sortHook';
import NoDataEmoji from '../../Components/Common/nodataEmoje';
import { TableStyles } from '../../Components/Common/tableStyle';
import { useTranslation } from 'react-i18next';
import { getCoupons, toggleCoupon } from '../../Components/Redux/Slice/Coupons/coupons';
import AddCoupon from './Coupons-Components/addCoupons';
import { dateFormate } from '../../Components/Common/dateFormate';
import EditCoupon from './Coupons-Components/editCoupons';
import WarningCouponModal from './Coupons-Components/warningCouponModal';

//Custome Cell UI for the data table action column
function ActionCell( row ) {
    // console.log(row);
    const {t, i18n} = useTranslation()
    const [openModal, setopenModal] = useState(false)
    const handleOpenModal = ()=>setopenModal(true)
    const handleCloseModal = ()=>setopenModal(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch()
    const open = Boolean(anchorEl);

    const {auth} = useSelector(state=>state)

    //warning modal state
    const [openWarningModal,setopenWarningModal] = useState(false)
    const [warnType,setwarnType] = useState(null)

    const handleOpenwarningModal = ()=>setopenWarningModal(true)
    const handleClosewarningModal = ()=>setopenWarningModal(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // const deleteFunc = ()=>{
    //     dispatch(deleteOffer(row?.id)).then( ()=>dispatch(getCoupons({page,size}))).then( ()=>handleCloseWarningModal() )
    // }
    return (
        <div className='action-wrapper relative'>
            <ListItemButton className='rounded-md' onClick={handleClick}><span><BiDotsHorizontalRounded className='text-xl' /></span></ListItemButton>
            <Menu
                id="datatable-action-menu"
                aria-labelledby="datatable-action-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                PaperProps={{ style: { boxShadow: 'none',padding:0} }}
                className='shadow-md p-0'
                sx={{'& .css-6hp17o-MuiList-root-MuiMenu-list' : {padding:0}}}
            >
                <div className={`${localStorage.getItem('lang')=== 'ar' ? '[&>li>svg]:ml-2 [&>li]:flex [&>li]:flex-row-reverse' : '[&>li>svg]:mr-2'} [&>li]:mb-2  [&>li>svg]:text-xl rounded-md overflow-hidden capitalize`}>
                    {/* <MenuItem className='text-mainColor hover:bg-[#b3c0f742]' onClick={handleOpenModal}>
                        {auth?.role === 'BRANCH_ADMIN' ? localStorage.getItem('lang')=== 'ar' ? <><IoMdEye />  عرض</> : <><IoMdEye />  View</> : localStorage.getItem('lang')=== 'ar' ? <><FaEdit /> تعديل</> : <><FaEdit /> Edit</>}
                    </MenuItem> */}
                    {row?.isActive ? 
                        <MenuItem onClick={()=>{
                            handleOpenwarningModal()
                            setwarnType('deactivate')
                        }} className='text-mainRed hover:bg-mainRed hover:bg-opacity-25 '><MdNotInterested /> {t('deliveryContractors.deactivate_provider')}</MenuItem>
                        :
                        <MenuItem onClick={()=>{
                            handleOpenwarningModal()
                            setwarnType('reactivate')
                        }} className='text-mainGreen hover:bg-mainGreen hover:bg-opacity-25 '><AiOutlineCheckCircle /> {t('deliveryContractors.reactivate_provider')}</MenuItem>
                    }
                </div>
            </Menu>
            {/* dispatch(deleteOffer(row?.id)).then( ()=>dispatch(getCoupons({page,size})) ) */}

            <EditCoupon open={openModal} close={handleCloseModal} data={row} type={'edit'} />
            <WarningCouponModal open={openWarningModal} close={handleClosewarningModal} warnType={warnType} data={row} />
        </div>
    );
}

const Coupons = () => {
    const {t,i18n} = useTranslation()
    const [activeTab, setActiveTab] = useState(0);
    const [activeRowData, setactiveRowData] = useState(null);
    const [open, setopen] = useState(false)
    const handleOpen = ()=>setopen(true)
    const handleClose = ()=>setopen(false)
    const [searchValue, setsearchValue] = useState('')
    const [status, setstatus] = useState('')

    const coupons = useSelector(state=>state?.coupons)

    const {currentPage} = useSelector((state) => state?.resetPagination);
    const {toggle} = useSelector((state) => state?.resetPagination);

    const btns = [
        {labelAr: "الكل",label:'all',param:`ALL`},
        {labelAr: "النشط",label:'active' ,param:`ACTIVE`},
        {labelAr: "الغير نشط",label:'Deactivated',param:`DEACTIVATED`},
    ]

    const filterBtn = (item,index)=>{
        setActiveTab(index)
        let status = item?.param
        setstatus(status)
        dispatch(getCoupons({status}))
    }

    useEffect(() => {
        // console.log(filter);
    }, [status])
    
    // console.log(selectedDate);
    // console.log(selectedDate.$M+1); display month but add 1 since it start with month 0


    const columns = [
        {
            id: 'ID',
            name: `ID`,
            selector: row => row?.id,
            sortable: false,
        },
        {
            id: 'code',
            name: `${t('coupons.columns.code')}`,
            selector: row => row?.code,
            sortable: true,
        },
        {
            id: 'createdAt',
            name: `${t('coupons.columns.createdAt')}`,
            selector: row => dateFormate(row?.createdAt),
            sortable: true,
        },
        {
            id: 'expiryDate',
            name: `${t('coupons.columns.expiryDate')}`,
            selector: row => dateFormate(row?.expiryDate),
            sortable: true,
        },
        {
            id: 'discountType',
            name: `${t('coupons.columns.discountType')}`,
            selector: row => <span className='font-semibold capitalize'>{row?.discountType?.toLowerCase()}</span>,
            sortable: true,
        },
        {
            id: 'value',
            name: `${t('coupons.columns.value')}`,
            selector: row => <span className='font-semibold'>{row?.value}</span>,
            sortable: true,
        },
        {
            id: 'status',
            name: `${t('coupons.columns.status')}`,
            cell: row => <span className={`bg-opacity-25 min-w-[100px] text-center capitalize rounded-md font-semibold p-2 ${row?.isActive ? 'bg-mainGreen text-mainGreen' : 'bg-mainRed text-mainRed'}`}>{row?.isActive ? 'Activated' : 'Deactivated'}</span>,
            sortable: false,
        },
        {
            id: 'maxAmount',
            name: `${t('coupons.columns.maxAmount')}`,
            selector: row => row?.maxAmount,
            sortable: true,
        },
        {
            id: 'maxRedemptionPerUser',
            name: `${t('coupons.columns.maxRedemptionPerUser')}`,
            cell: row => row?.maxRedemptionsPerUser,
            sortable : true
        },
        
        {
            id: 'maxTotalRedemptions',
            name: `${t('coupons.columns.maxTotalRedemptions')}`,
            selector: row => row?.maxTotalRedemptions,
            sortable: true,
        },
        {
            id: 'actions',
            name: `${localStorage.getItem('lang') === 'ar' ? 'اوامر' : 'Actions'}`,
            allowOverflow: true,
            button : true,
            cell: ActionCell
        },
    ];
    
    const data = coupons?.coupons?.data

    // console.log(data);
    // open calander overlay
    const {dateOverlay}= useSelector(state=>state.overlay)
    const dispatch = useDispatch()

    const handleButton = ()=>{
        dispatch(openHandler({dateShow:!dateOverlay}))
    }

    useEffect(() => {
    //   console.log(activeRowData);
    }, [activeRowData])

    const [page, setpage] = useState(1)
    const [size, setsize] = useState(10)

    const handlePageChange = page => {
        setpage(page);
    };

    const handleRowChange = rows => {
        setsize(rows);
    };
    
    const handleSearch = ()=>{
        dispatch(getCoupons({searchValue}))
    }

    const handleReset = ()=>{
        dispatch(getCoupons({page,size}))
        setsearchValue('')
    }

    useEffect(() => {
        setpage(currentPage)
    }, [toggle])
    
    useEffect(() => {
    //   console.log(searchValue);
    }, [searchValue])

    const {handleRemoteSort, icon} = useRemoteSort(getCoupons,dispatch,page,size,searchValue,null,null,null,status)

    
    const [selectedColumns, setSelectedColumns] = useState(['code', 'createdAt', 'expiryDate', 'discountType', 'value', 'status', 'actions']);
    
    const [openMenu, setopenMenu] = useState(false)
    const [visibleColumns, setVisibleColumns] = useState(columns.filter((column) => selectedColumns.includes(column.id)));

    const handleOpenMenu = ()=>{
        setopenMenu(true)
    }
    const handleCloseMenu = ()=>{
        setopenMenu(false)
    }

    const handleVisibleColumnsChange = useCallback((newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    }, []);

    const {file} = useSelector(state=>state)

    useEffect(() => {
        setSelectedColumns(['code', 'createdAt', 'expiryDate', 'discountType', 'value', 'status', 'actions']);
    }, []);
    
    useEffect(() => {
        setVisibleColumns(columns.filter((column) => selectedColumns.includes(column.id)));
    }, [selectedColumns]);


    return ( 
        <article className="offers-wrapper pb-5">
            <section className="order-title">
                <h1 className="text-[#333] font-bold text-3xl mb-4 font-playfair capitalize">{t('coupons.title')}</h1>
            </section>

            <section className={`flex items-center justify-end gap-x-3 mt-4 ${localStorage.getItem('lang') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
           
                <Button onClick={handleOpen} className={`capitalize text-white bg-mainGreen rounded-md py-2 flex justify-between gap-x-2 ${localStorage.getItem('lang')} ? 'pl-2' : 'pr-2'}`}> <IoMdAddCircle className={`w-6 h-6 rounded-[50%] ${localStorage.getItem('lang')} ? 'ml-2' : 'mr-2' `} /> <span className='text-sm font-playfair'>{t('coupons.add_btn')}</span></Button>


                <div className="flex">
                    <ColumnSelection columns={columns} handleOpen={handleOpenMenu} defColumns={selectedColumns} onVisibleColumnsChange={handleVisibleColumnsChange} open={openMenu} close={handleCloseMenu} />
                    {/* <Button onClick={handleOpenMenu} className='capitalize text-white bg-mainColor py-2 px-2 pr-2 rounded-md'> <LuMousePointerClick className='w-7 h-7 m-auto' />select columns</Button> */}
                </div>
            </section>

            <section className="offers-control-wrapper flex justify-end items-center mt-5 w-full">
                {/* <div className='left-actions flex items-center'>

                    <div className="offers-filter-wrapper flex justify-evenly [&>div]:mr-2">
                        <div className="px-1 py-1 bg-[#ffffff] rounded-sm">
                            {btns?.map((item,index)=>{
                                return(
                                    <button key={index} className={`capitalize font-bold text-sm text-[#333] px-4 py-2 lg:px-2 xl:px-6 rounded-sm transition-all duration-300 ${activeTab === index ? 'bg-[#F4F6FC]' : null}`}
                                        onClick={()=>filterBtn(item,index)}
                                    >
                                        {localStorage.getItem('lang') === 'ar' ? item.labelAr : item.label}
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                </div> */}
                

                <div className=''>
                    <FormControl 
                        className="search-form [&>div>input]:py-[.6rem]"
                        sx={{ m: 1, width: '35ch',backgroundColor:'white',borderRadius:'6px','& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input':{padding : '10px'}, '& fieldset': { borderColor: 'primary.main',border:'none' } }} variant="outlined">
                        <OutlinedInput
                            type="search"
                            id="outlined-adornment-search"
                            placeholder={localStorage.getItem('lang') === 'ar' ? 'ابحث في الكوبونات...' : 'Search Coupons...'}
                            onChange={(e)=>{
                                setsearchValue(e.target.value.trim())
                                if (!e.target.value) {
                                    handleReset();
                                }}}
                            startAdornment={
                            <InputAdornment position="start">
                                
                                <IconButton
                                    aria-label="search"
                                    onClick={handleSearch}
                                    edge="start"
                                >
                                    <CiSearch />
                                </IconButton>
                            </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
            </section>

            <section className="datatable-wrapper mt-5 bg-white rounded-md relative">
                
                {data?.length === 0 ? 
                    <div className='p-8'><NoDataEmoji /></div>
                : 
                    <>
                        <DataTable
                            direction={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}
                            columns={visibleColumns}
                            data={data}
                            pagination
                            paginationPerPage = {size}
                            paginationRowsPerPageOptions = {[10,50,100]}
                            paginationServer
                            paginationTotalRows={coupons?.coupons?.meta?.total}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowChange}
                            customStyles={TableStyles}
                            highlightOnHover
                            // selectableRows
                            // selectableRowsHighlight
                            // onSelectedRowsChange={(e)=>console.log(e)}
                            onRowClicked={(row)=>setactiveRowData(row)}
                            sortServer
                            sortIcon={icon}
                            onSort={handleRemoteSort}
                            paginationDefaultPage={page}
                            keyField="id"
                            paginationResetDefaultPage = {true}
                        />
                    </>
                }
            </section>

            {/* <section className='offer-preview-wrapper mt-5'>
                <div className='offer-preview-title font-semibold mb-3 text-[#464E5F] capitalize'>
                    <h2>{t('coupons.coupon_preview')}</h2>
                </div>

                <div className='offer-preview w-full flex h-[250px] bg-white justify-center items-center rounded-md'>
                    <div className='relative bg-mainGreen rounded-lg w-[90%] lg:w-[75%] m-auto h-[80px] max-w-[550px]'>
                        <div className={`discount absolute top-0 w-[70px] h-[70px] text-center ${localStorage.getItem('lang') === 'ar' ? 'right-3' : 'left-3'}`}>
                            <div className='discount-wrapper relative w-full h-full'>
                                <img src={discount} alt='discount' className='absolute w-full h-full' />
                                <div className='absolute top-0 [&>p]:m-0 [&>p]:p-0 [&>p]:leading-[1] w-full capitalize font-[500] mt-1 h-[90%] flex flex-wrap items-center justify-center [&>p]:basis-full'>
                                    <div>
                                        <p className='text-[17px] text-white text-sm'>{activeRowData ? activeRowData?.discountType === 'PERCENTAGE' ? activeRowData?.offerValue + "%" : activeRowData?.offerValue + " SAR" : '--'}</p>
                                        <p className='text-[17px] text-white text-sm'>off</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`w-[83%] pt-4 ${localStorage.getItem('lang') === 'ar' ? 'mr-auto' : 'ml-auto'}`}>
                            <p className='font-semibold text-white'> {localStorage.getItem('lang') === 'ar' ? 'خصم يصل الى' : ' up to'} {activeRowData ? activeRowData?.discountType === 'PERCENTAGE' ? activeRowData?.offerValue + "%" : activeRowData?.offerValue + " SAR" : '--'} {localStorage.getItem('lang') === 'ar' ? 'استخدم كود' : ' Discount use'} “{activeRowData ? activeRowData?.couponCode : 'COUPON CODE'}” </p>
                            <p className='text-white font-light text-[13px] mt-1'>{localStorage.getItem('lang') === 'ar' ? 'جربه الان , العرض ينتهى في' : 'Try it now EXP'} {activeRowData ? activeRowData?.expirationDate?.substring(0, 10) : '-/-/--'}</p>
                        </div>
                    </div>
                </div>
            </section> */}

            <AddCoupon open={open} close={handleClose} />

            {file?.loading || coupons?.loading ? <Overlay /> : null}
        </article>
    );
}

export default Coupons;

